import React, { useState } from "react";
import { useEffect } from "react";
import {Form, Modal, Button, FormCheck, Row, Col} from "react-bootstrap";
import fetchApi from "utils/fetchApi";
import { toast } from "react-toastify";

function RejectAfterTask({
    taskItem,
    modalRejectAfterTask,
    handleCloseRejectAfterCloseModel,
    setTaskLoading,
    setTaskItem
}) {
    // components
    const { Control, Label, Group } = Form;
    const { Header, Title, Body, Footer } = Modal;
    const [rejectReason, setRejectReason] = useState([]);
    const [rejectMessage, setRejectMessage] = useState('');
    
    const handelOnChangeCheckbox = (e) => {
        console.log(e.target.value);
        if(!rejectReason.includes(e.target.value)){
            setRejectReason(prev => {return [...prev,e.target.value]}  )
        }else{
            setRejectReason(prev => {
                return prev.filter(i => i !== e.target.value);
            })
        }
    }

    const handleOnChangeTextarea = (e) => {
        setRejectMessage(e.target.value);
    }
    // server function
    const rejectAfterTask = () => {
        const data = {
            reason: rejectReason,
            message: rejectMessage
        };
        console.log(data)
        const url = `/task/${taskItem.id}/reject-after`;
        setTaskLoading(true);
        fetchApi
            .post(url, data)
            .then((res) => {
                console.log(res)
                toast.success(res.data.message);
                setRejectReason([]);
                setRejectMessage('');
                setTaskItem(res.data.task);
                setTaskLoading(false);
            })
            .catch((err) => {
                toast.error(err);
                setTaskLoading(false);
            });
        handleCloseRejectCloseModel();
    };

    useEffect(() => {
    }, []);

    return (
        <Modal show={modalRejectAfterTask} onHide={handleCloseRejectAfterCloseModel} size='lg'>
            <Header closeButton>
                <Title>Rad etish</Title>
            </Header>
            <Body className="space-y-3">
                <Group as={Row} className='mb-3' style={{alignItems:"center"}}>
                    <Col sm="2">
                        <Control
                            style={{height:"20px", cursor:"pointer"}}
                            value="Geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko'chmas mulk ekspertizasi, arxitektura, yer tuzish va yer kadastri yo'nalishlari bo'yicha oliy ma'lumotga ega bo'lishi"
                            onChange={handelOnChangeCheckbox}
                            type="checkbox"
                            name="1"
                        />
                    </Col>

                    <Label
                        style={{fontSize:"15px"}}
                        column
                        sm="10">Geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko'chmas mulk ekspertizasi, arxitektura, yer tuzish va yer kadastri yo'nalishlari bo'yicha oliy ma'lumotga ega bo'lishi.</Label>
                </Group>
                <Group as={Row} className='mb-3' style={{alignItems:"center"}}>
                    <Col sm="2">
                        <Control
                            style={{height:"20px", cursor:"pointer"}}
                            value="Geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko'chmas mulk ekspertizasi, arxitektura, yer tuzish va yer kadastri yo'nalishlari bo'yicha kamida bir yil ish stajiga ega bo'lishi yoki ish stajiga ega bo'lmagan holda kadastr munosabatlari sohasida kasbiy qayta tayyorlash kurslari bo'yicha ta'lim olgan bo'lishi"
                            onChange={handelOnChangeCheckbox}
                            type="checkbox"
                            name="2"
                        />
                    </Col>

                    <Label
                        style={{fontSize:"15px"}}
                        column
                        sm="10">Geodeziya, bino va inshootlar qurilishi, qurilish ekspertizasi, ko'chmas mulk ekspertizasi, arxitektura, yer tuzish va yer kadastri yo'nalishlari bo'yicha kamida bir yil ish stajiga ega bo'lishi yoki ish stajiga ega bo'lmagan holda kadastr munosabatlari sohasida kasbiy qayta tayyorlash kurslari bo'yicha ta'lim olgan bo'lishi.</Label>
                </Group>
                <Group as={Row} className='mb-3' style={{alignItems:"center"}}>
                    <Col sm="2">
                        <Control
                            style={{height:"20px", cursor:"pointer"}}
                            value="Taqdim etilgan hujjatlarda kamchilik bor yoki nato'g'ri hujjatlar yuborilgan"
                            onChange={handelOnChangeCheckbox}
                            type="checkbox"
                            name="3"
                        />
                    </Col>

                    <Label
                        style={{fontSize:"15px"}}
                        column
                        sm="10">Taqdim etilgan hujjatlarda kamchilik bor yoki nato'g'ri hujjatlar yuborilgan</Label>
                </Group>
                <Group as={Row} className='mb-3' style={{alignItems:"center"}}>
                    <Col sm="2">
                        <Control
                            style={{height:"20px", cursor:"pointer"}}
                            value="Ushbu ariza bir vaqtda ikki marta yaratilgan"
                            onChange={handelOnChangeCheckbox}
                            type="checkbox"
                            name="4"
                        />
                    </Col>

                    <Label
                        style={{fontSize:"15px"}}
                        column
                        sm="10">Ushbu ariza bir vaqtda ikki marta yaratilgan</Label>
                </Group>
                <Control
                    onChange={handleOnChangeTextarea}
                    type="text"
                    as="textarea"
                    placeholder="Qo'shimcha ma'lumotlar"
                />
            </Body>
            <Footer>
                <Button variant="secondary" onClick={handleCloseRejectAfterCloseModel}>
                    Bekor qilish
                </Button>
                <Button variant="danger" onClick={rejectAfterTask}>
                    Rad etish
                </Button>
            </Footer>
        </Modal>
    );
}

export default RejectAfterTask;
