import React, {useState, useEffect} from "react";
import {Card, Container, Row, Col, Button, Tab, ListGroup, Tabs} from "react-bootstrap";
import {FaDownload} from "react-icons/fa";
import fetchApi from "utils/fetchApi";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import RejectTask from "../components/Task/RejectModal";
import RejectAfterTask from "../components/Task/RejectAfterModal";
import {toast} from "react-toastify";
import AcceptModal from "../components/Task/AcceptModal";
import SendExamInfo from "../components/Task/SendExamInfo";
import format from "utils/moment";
import SendExamResult from "../components/Task/SendExamResult";
import ChangeProtocolModal from "../components/Task/ChangeProtocolModal";
import ChangeGroupModal from "../components/Task/ChangeGroupModal";

const TaskView = () => {
    const params = useParams();
    const history = useHistory();
    const {user} = useSelector(state => state);
    // states

    const [modalRejectTask, setModalReject] = useState(false);
    const [modalRejectAfterTask, setModalRejectAfter] = useState(false);
    const [modalAcceptTask, setModalAccept] = useState(false);
    const [modalSendExamInfo, setModalSendExamInfo] = useState(false);
    const [modalSendExamResult, setModalSendExamResult] = useState(false);
    const [taskItem, setTaskItem] = useState("");
    const [taskHistory, setTaskHistory] = useState([]);
    const [historyLoading, setTaskHistoryLoading] = useState(true);
    const [taskLoading, setTaskLoading] = useState(true);
    const [protocols, setProtocols] = useState([]);
    const [groups, setGroups] = useState([]);
    const [modalChangeProtocol, setModalChangeProtocol] = useState(false);
    const [modalChangeGroup, setModalChangeGroup] = useState(false);

    //server funtions

    const handleOpenTaskRejectModel = () => setModalReject(true);
    const handleOpenTaskRejectAfterModel = () => setModalRejectAfter(true);
    const handleOpenTaskAcceptModel = () => setModalAccept(true);

    const handleOpenSendExamInfoModal = () => setModalSendExamInfo(true);
    const handleOpenSendExamResultModal = () => setModalSendExamResult(true);
    const handleCloseRejectCloseModel = () => {
        setModalReject(false)
    };
    const handleCloseRejectAfterCloseModel = () => {
        setModalRejectAfter(false)
    };
    const handleCloseAcceptCloseModel = () => {
        setModalAccept(false)
    };
    const handleCloseSendExamInfoModal = () => {
        setModalSendExamInfo(false)
    };
    const handleCloseSendExamResultModal = () => {
        setModalSendExamResult(false)
    };
    const handleOpenChangeProtocolModal = () => {
        setModalChangeProtocol(true)
    };
    const handleCloseChangeProtocolModal = () => {
        setModalChangeProtocol(false)
    };
    const handleOpenChangeGroupModal = () => {
        setModalChangeGroup(true)
    };
    const handleCloseChangeGroupModal = () => {
        setModalChangeGroup(false)
    };
    const handleDownloadExamResult = () => {
        fetchApi.get(`/task/${taskItem.id}/get-exam-result-url`).then((res) => {
            const link = document.createElement("a");
            link.href = res.data;
            link.download = res.data;
            link.click()
        })
    }
    const handleDownloadCertificate = () => {
        fetchApi.get(`/task/${taskItem.id}/get-certificate-url`).then((res) => {
            const link = document.createElement("a");
            link.href = res.data;
            link.download = res.data;
            link.click()
        })
    }
    const handleDownloadEpiguCertificate = () => {
        const link = document.createElement("a");
        link.href = `https://my.gov.uz/get-service-file/${taskItem.epiguId}/${taskItem.certificate}`;
        link.download = `https://my.gov.uz/get-service-file/${taskItem.epiguId}/${taskItem.certificate}`;
        link.click()
    }
    const handleDownloadStatement = () => {
        fetchApi.get(`/task/${taskItem.epiguId}/get-statement-url`).then((res) => {
            const link = document.createElement("a");
            link.href = res.data;
            link.download = res.data;
            link.click()
        })
    }
    const getProtocol = () => {
        fetchApi.get(`protocol/for-task`).then((res) => {
            setProtocols(res.data);
            setTaskLoading(false);
        });
    }
    // const getGroups = () => {
    //     fetchApi.get("/exam/get-groups").then((res) => {
    //         const {groups} = res.data;
    //         setGroups(groups);
    //         setTaskLoading(false);
    //     });
    // }
    const getTask = async (id) => {
        await fetchApi.get(`task/${id}/view`).then((res) => {
            setTaskItem(res.data);
            setTaskLoading(false);
        });
    }

    const getTaskHistory = async (id) => {
        await fetchApi.get(`task/${id}/history`).then((res) => {
            setTaskHistory(res.data);
            setTaskHistoryLoading(false);
        });
    }

    const sendTaskFinish = async () => {
        const url = `/task/${taskItem.id}/finish`;
        setTaskLoading(true);
        fetchApi
            .post(url)
            .then((res) => {
                setTaskItem(res.data.task);
                setTaskLoading(false);
                toast.success(res.data.message);
            })
            .catch((err) => {
                setTaskLoading(false);
                toast.error(err);
            });
    }

    useEffect(() => {
        if (params?.id) {
            getTask(params.id);
            getTaskHistory(params.id);
        }
        getProtocol();
        // getGroups()
    }, []);
    return (
        <div className="selector-group">
            {taskLoading ? (
                ""
            ) : (
                <div>
                    {taskItem.generalStatus?.key === "new" ? (
                        <div>
                            <RejectTask
                                taskItem={taskItem}
                                modalRejectTask={modalRejectTask}
                                handleCloseRejectCloseModel={handleCloseRejectCloseModel}
                                setTaskLoading={setTaskLoading}
                                setTaskitem={setTaskItem}
                                protocols={protocols}
                            />
                            <RejectAfterTask
                                taskItem={taskItem}
                                modalRejectAfterTask={modalRejectAfterTask}
                                handleCloseRejectAfterCloseModel={handleCloseRejectAfterCloseModel}
                                setTaskLoading={setTaskLoading}
                                setTaskitem={setTaskItem}
                            />

                            <AcceptModal
                                taskItem={taskItem}
                                modalAcceptTask={modalAcceptTask}
                                handleCloseAcceptCloseModel={handleCloseAcceptCloseModel}
                                setTaskLoading={setTaskLoading}
                                setTaskItem={setTaskItem}
                                protocols={protocols}
                            />
                        </div>
                    ) : (
                        taskItem.generalStatus?.key == "process" && taskItem.localStatus?.key == 'sending-exam-info' ? (
                            <div>
                                <SendExamInfo
                                    taskItem={taskItem}
                                    modalSendExamInfo={modalSendExamInfo}
                                    handleCloseSendExamInfoModal={handleCloseSendExamInfoModal}
                                    setTaskLoading={setTaskLoading}
                                    setTaskItem={setTaskItem}
                                />
                            </div>
                        ) : (taskItem.localStatus?.key == 'sending-exam-result' ? (
                            <div>
                                <SendExamResult
                                    taskItem={taskItem}
                                    modalSendExamResult={modalSendExamResult}
                                    handleCloseSendExamResultModal={handleCloseSendExamResultModal}
                                    setTaskLoading={setTaskLoading}
                                    setTaskItem={setTaskItem}
                                />
                            </div>
                        ) : (""))
                    )}
                    {/* {(['new', 'process', 'waiting-payment'].includes(taskItem?.generalStatus?.key) && user.role.shortName == "superadmin") ? (
                        <div>
                            <ChangeProtocolModal
                                taskItem={taskItem}
                                modalAcceptTask={modalChangeProtocol}
                                handleCloseChangeProtocolModal={handleCloseChangeProtocolModal}
                                setTaskLoading={setTaskLoading}
                                setTaskitem={setTaskItem}
                                protocols={protocols}
                            />
                            <ChangeGroupModal
                                taskItem={taskItem}
                                modalAcceptTask={modalChangeGroup}
                                handleCloseChangeGroupModal={handleCloseChangeGroupModal}
                                setTaskLoading={setTaskLoading}
                                setTaskitem={setTaskItem}
                                groups={groups}
                            />
                        </div>
                    ) : ("")} */}
                </div>

            )}

            <Container fluid>
                <Row>
                    <Col md="6">
                        {taskLoading ? (
                            <h1>Loading...</h1>
                        ) : (
                            <Card className='rounded shadow p-2'>
                                <ul className="flush">
                                    <li className='p-2'><b>Buyurtma raqami:</b> {taskItem.epiguId}</li>
                                    <li className='p-2'><b>Tizimdagi raqami:</b> {taskItem.id}</li>
                                    <li className='p-2'><b>Tizimdagi holati:</b> {taskItem.generalStatus?.name}</li>
                                    <li className='p-2'><b>Guruh:</b> {taskItem.group?.name}</li>
                                    <hr/>
                                    <li className='p-2'><b>F.I.SH:</b> {taskItem.fullName}</li>
                                    <li className='p-2'><b>Passport:</b> {taskItem.passport}</li>
                                    <li className='p-2'><b>PINFL:</b> {taskItem.pinfl}</li>
                                    <li className='p-2'><b>Manzil:</b> {taskItem.address}</li>
                                    <li className='p-2'><b>Telefon:</b> {taskItem.phone}</li>
                                    <li className='p-2'>
                                        <b>Shaxs:</b> {taskItem.userType == 'I' ? ("Jismoniy") : ("Yuridik")}</li>
                                    <li className='p-2'><b>Arizani yuklab olish:</b>
                                        <Button variant="success" className='m-2' style={{padding: '2px 8px'}}
                                                onClick={handleDownloadStatement}>
                                            <FaDownload/>
                                        </Button>
                                    </li>
                                    {taskItem?.generalStatus?.key == "finished" ? (
                                        <li className='p-2'><b>Shahodatnomani yuklab olish:</b>
                                            <Button variant="success" className='m-2' style={{padding: '2px 8px'}}
                                                    onClick={handleDownloadCertificate}>
                                                <FaDownload/>
                                            </Button>
                                        </li>
                                    ) : ("")}
                                    {taskItem?.certificate != null ? (
                                        <li className='p-2'><b>Sertifikatni yuklab olish:</b>
                                            <Button variant="success" className='m-2' style={{padding: '2px 8px'}}
                                                    onClick={handleDownloadEpiguCertificate}>
                                                <FaDownload/>
                                            </Button>
                                        </li>
                                    ) : ("")}
                
                                        {taskItem?.examResult && taskItem?.examResult?.status != "notcome" ? (
                                        <div className='p-2'>
                                            <Button
                                                className='btn btn-success m-2'
                                                onClick={handleDownloadExamResult}
                                            >
                                                Imtihon natijalarini yuklab olish
                                            </Button>
                                        </div>
                                    ) : ("")}
                                    {(taskItem?.protocol?.generalStatus?.key == "second-approved" && ["superadmin", "commision-assistant", "manager"].includes(user.role.shortName) && taskItem?.generalStatus?.key == "exam-result-sent") ? (
                                        <div className='p-2'>
                                            <Button
                                                className='btn btn-success m-2'
                                                onClick={sendTaskFinish}
                                            >
                                                Arizani yakunlash
                                            </Button>
                                        </div>
                                    ) : ("")}

                                    {taskItem.generalStatus?.key === "process" && ["superadmin", "commision-assistant", "manager"].includes(user.role.shortName) && taskItem.localStatus?.key == 'sending-exam-info' ? (
                                        <div>
                                            <Button
                                                className='btn btn-success m-2'
                                                onClick={handleOpenSendExamInfoModal}
                                            >
                                                Imtihon ma'lumotlarini jo'natish
                                            </Button>
                                        </div>
                                    ) : (taskItem.localStatus?.key == 'sending-exam-result' && ["superadmin", "commision-assistant", "manager"].includes(user.role.shortName) ? (
                                        <div>
                                            <Button
                                                className='btn btn-success m-2'
                                                onClick={handleOpenSendExamResultModal}
                                            >
                                                Imtihon natijalarini jo'natish
                                            </Button>
                                        </div>
                                    ) : (''))}

                                </ul>
                            </Card>
                        )}
                    </Col>
                    <Col md="6">
                        {taskLoading ? (
                            <h1>Yuklanmoqda...</h1>
                        ) : (
                            <Tabs
                                defaultActiveKey="oliy"
                                id="uncontrolled-tab-example"
                            >
                                <Tab eventKey="oliy" title="Oliy ta'lim ma'lumotlari">
                                    <Card className='rounded shadow p-2'>
                                        {taskItem.eduInfo?.map((item, idx) => (
                                            <ListGroup key={`edu-${idx}`} className='my-1'>
                                                <ListGroup.Item><b>{idx + 1}-Oliy ta'lim
                                                    ma'lumotlari:</b></ListGroup.Item>
                                                <ListGroup.Item><b>Oliy talim nomi:</b> {item.edu_name.value}
                                                </ListGroup.Item>
                                                <ListGroup.Item><b>O'quv
                                                    yili:</b> {(item.begin_year.value ?? "Belgilanmagan")+ "-" + (item.finish_year.value??"Belgilanmagan")}
                                                </ListGroup.Item>
                                                <ListGroup.Item><b>Yo'nalish:</b> {item.speciality.value}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        ))}
                                    </Card>
                                </Tab>
                                <Tab eventKey="citizen-history" title="Mehnat faoliyati">
                                    <Card className='rounded shadow p-2'>
                                        {taskItem.experiences?.map((item, idx) => (
                                            <ListGroup key={'experience-' + idx} className='my-2'>
                                                <ListGroup.Item><b>{idx + 1}-Ish tajribasi:</b></ListGroup.Item>
                                                <ListGroup.Item><b>Korxona:</b> {item.company_name}</ListGroup.Item>
                                                <ListGroup.Item><b>Lavozimi:</b> {item.position_name}</ListGroup.Item>
                                                <ListGroup.Item><b>Bo'limi:</b> {item.structure_name}</ListGroup.Item>
                                                <ListGroup.Item><b>Vaqti:</b> {item.start_date + "  -  "} {item.end_date ?? "Xozirgi vaqtgacha"}
                                                </ListGroup.Item>
                                            </ListGroup>
                                        ))}
                                    </Card>
                                </Tab>
                            </Tabs>
                        )}
                        {taskLoading ? (
                            <h1>Yuklanmoqda...</h1>
                        ) : (
                            taskItem.generalStatus?.key === "new" && ["superadmin", "commision", "commision-assistant", "manager" ].includes(user.role.shortName) ? (
                                <div style={{marginTop: "15px"}}>
                                    <Card className='rounded shadow p-2'>
                                        <Button variant="danger" className='btn btn-danger m-2'
                                                onClick={handleOpenTaskRejectModel}>
                                            Rad etish
                                        </Button>
                                        <Button
                                            className='btn btn-success m-2'
                                            onClick={handleOpenTaskAcceptModel}
                                        >
                                            Qabul qilish
                                        </Button>
                                    </Card>
                                </div>
                            ) : (
                                <div>


                                </div>
                            )
                        )}
                        {taskLoading ? (
                            <h1>Yuklanmoqda...</h1>
                        ) : (
                            taskItem.generalStatus?.key === "process" && ["superadmin", "commision", "commision-assistant", "manager" ].includes(user.role.shortName) ? (
                                <div style={{marginTop: "15px"}}>
                                    <Card className='rounded shadow p-2'>
                                        <Button variant="danger" className='btn btn-danger m-2'
                                                onClick={handleOpenTaskRejectAfterModel}>
                                            Rad etish
                                        </Button>
                                    </Card>
                                </div>
                            ) : (
                                <div>


                                </div>
                            )
                        )}

                        {(['process', 'waiting-payment'].includes(taskItem?.generalStatus?.key) && user.role.shortName == "superadmin") ? (
                            <Card className='rounded shadow p-2 mt-2'>
                                <Button className='btn btn-info m-2'
                                        onClick={handleOpenChangeProtocolModal}>
                                    Bayonnomani o'zgartirish
                                </Button>
                                <Button className='btn btn-default m-2'
                                        onClick={handleOpenChangeGroupModal}>
                                    Guruhni o'zgartirish
                                </Button>
                            </Card>
                        ) : ("")}

                    </Col>

                    {historyLoading ? ("") : (
                        <Col md="12 mt-6">
                            <Card className='rounded shadow p-2'>
                                <ul className="flush">
                                    {taskHistory.map((item, idx) => (
                                        <div key={idx}>
                                            <li className='p-2'>{format.formatDate(item.createdAt)} - {item.userId ? (item.user.firstname + " " + item.user?.lastname + " - ") : ("")}{item.message}</li>
                                            <hr/>
                                        </div>
                                    ))}
                                </ul>
                            </Card>
                        </Col>
                    )}

                </Row>
            </Container>
        </div>
    );
}
export default TaskView;